import React, { FC, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

import shareNative from '~/app/components/ModalShare/lib/shareNative';
import { useOpenDialog } from '~/app/components/DialogBoxWithStages';
import { ActionsDialogProps } from './ActionsDialog';
import { SelectedItem } from '~/app/lib/store/types';

const ActionsDialogLazy = dynamic({
  ssr: false,
  loader: () => import('./ActionsDialog'),
});

const ModalShareLazy = dynamic({
  ssr: false,
  loader: () => import('~/app/components/ModalShare'),
});

const CustomLinksDialogLazy = dynamic({
  ssr: false,
  loader: () => import('~/app/components/CustomPageLinksDialog'),
});

const ClaimPageDialog = dynamic({
  ssr: false,
  loader: () => import('../ClaimPageDialog'),
});

export interface ItemPageActionsProps
  extends Omit<ActionsDialogProps, 'openShareModal' | 'openCustomLinksDialog'> {
  isOpen: boolean;
  shareText: string;
  shareUrl: string;
  item: SelectedItem;
}

const ItemPageActions: FC<ItemPageActionsProps> = ({
  isOpen,
  isOwned,
  shareText,
  shareUrl,
  item,
  ...props
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [customLinksDialogOpen, openCustomLinksDialog] =
    useOpenDialog('customLinks');

  const [claimPageDialogOpen] = useOpenDialog('claimPage');

  const openShareModal = useCallback(() => {
    const success = shareNative({ url: shareUrl, text: shareText });
    if (success) return;

    setShareModalOpen(true);
  }, [shareUrl, shareText]);

  return (
    <>
      {isOpen && (
        <ActionsDialogLazy
          {...props}
          openShareModal={openShareModal}
          openCustomLinksDialog={() => {
            openCustomLinksDialog();
          }}
          isOwned={isOwned}
        />
      )}
      {shareModalOpen && (
        <ModalShareLazy onClose={() => setShareModalOpen(false)} />
      )}
      {customLinksDialogOpen && (
        <CustomLinksDialogLazy
          dialogId="customLinks"
          generalLink={shareUrl}
          item={item}
        />
      )}
      {claimPageDialogOpen && <ClaimPageDialog />}
    </>
  );
};

export default ItemPageActions;
