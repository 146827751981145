import { useSelector } from '~/app/lib/store/redux';
import { useItemContext } from './ItemPageContext';
import { selectUserCountry } from '~/app/lib/store/session/selectors';

const PRERELEASE_BLOCKED_COUNTRIES = ['IN'];

export const useCountryBlocked = () => {
  const item = useItemContext();
  const country = useSelector(selectUserCountry);

  if (
    item.data.item.type === 'album' &&
    item.data.item.isPrerelease &&
    !item.data.item.isDraft &&
    !item.data.item.userCanEdit
  ) {
    return PRERELEASE_BLOCKED_COUNTRIES.includes(country);
  }

  return false;
};
