import { useEffect } from 'react';

import { useAppHydrated } from '~/app/lib/utils/onceAppHydrated';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useEmitter } from '~/app/lib/hooks/useEmitter';
import useOneTrust from '~/app/components/OneTrust/useOneTrust';
import { ItemContext } from '../types';

const useCustomTracking = (itemContext: ItemContext) => {
  const appHydrated = useAppHydrated();
  const { pixelsEnabled } = useOneTrust();
  const emitter = useEmitter();

  const resolvedFacebookPixelId =
    AddonTypes.FB_PIXEL in itemContext.addons
      ? itemContext.addons[AddonTypes.FB_PIXEL]?.pixelId
      : itemContext.data.item.primaryOwnerAccount?.config?.facebookPixelId;

  useEffect(() => {
    // don't block critical path
    if (!appHydrated) return;

    // don't load/run pixels is user hasn't opted-in
    if (!pixelsEnabled) return;

    let removeFacebookListeners;
    let isTornDown = false;

    if (resolvedFacebookPixelId) {
      import('./facebookPixel').then(({ installFacebookPixel }) => {
        // protect against zombie callback
        if (isTornDown) return;

        removeFacebookListeners = installFacebookPixel({
          pixelId: resolvedFacebookPixelId,
          emitter,
        });
      });
    }

    return () => {
      if (removeFacebookListeners) removeFacebookListeners();
      isTornDown = true;
    };
  }, [resolvedFacebookPixelId, appHydrated, pixelsEnabled]);
};

export default useCustomTracking;
